import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import {Fill, Stroke, Style} from "ol/style";
import {tileGrid} from "./Common";
import {DEFAULT_FEATURE_FILL_COLOR, FEATURE_LAYER_OPACITY, FEATURE_STROKE_WIDTH} from "../constants";

export const eventLayer = new VectorLayer({
    source: new VectorSource({
        tileGrid: tileGrid
    }),
    style: (feature) => {
        let color = feature.getGeometry().getProperties()["color"];

        return new Style({
            fill: new Fill({color}),
            stroke: new Stroke({color: DEFAULT_FEATURE_FILL_COLOR, width: FEATURE_STROKE_WIDTH}),
        })
    },
    opacity: FEATURE_LAYER_OPACITY
})