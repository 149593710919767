import {
    ClassicEditor,
    Bold,
    Italic,
    Paragraph,
    SimpleUploadAdapter,
    AutoImage,
    Autosave,
    ImageBlock,
    ImageInsert,
    ImageInsertViaUrl,
    ImageToolbar,
    ImageUpload, PictureEditing, Link,
} from 'ckeditor5';

const createNewEditor = async (editor_id) => {
    return ClassicEditor
        .create( document.querySelector( editor_id ), {
            simpleUpload: {
                // Upload the image to the server
                uploadUrl: '/api/image'
            },
            toolbar: {
                items: ['undo', 'redo', '|', 'bold', 'italic', '|', 'link', 'insertImage'],
                    shouldNotGroupWhenFull: false
            },
            plugins: [
                AutoImage,
                Autosave,
                Bold,
                ImageBlock,
                ImageInsert,
                ImageInsertViaUrl,
                ImageToolbar,
                ImageUpload,
                Italic,
                Link,
                Paragraph,
                PictureEditing,
                SimpleUploadAdapter,
            ],
            image: {
                toolbar: ['imageTextAlternative']
            }
        }
    )
}

export {createNewEditor}