import Handlebars from "handlebars";

// import the templates
import eventTypeMenu from '/templates/event-type-menu.handlebars?url&raw';
import eventTypeFilter from '/templates/event-type-filter.handlebars?url&raw';
import newEventColors from '/templates/create-event-color-options.handlebars?url&raw';
import eventAttributeInput from '/templates/event-attributes-input.handlebars?url&raw';
import viewEventDetails from '/templates/view-event-details.handlebars?url&raw';
import editEventForm from '/templates/edit-event-form.handlebars?url&raw';
import searchFilterForm from '/templates/search-filters.handlebars?url&raw';
import searchListTemplate from '/templates/search-list-template.handlebars?url&raw';
import searchListItemTemplate from '/templates/search-list-item.handlebars?url&raw';
import searchEventTypeFiltersTemplate from '/templates/search-event-type-filters.handlebars?url&raw';

// register the templates
Handlebars.registerPartial('event-type-menu', eventTypeMenu);
Handlebars.registerPartial('event-type-filter', eventTypeFilter);
Handlebars.registerPartial('create-event-color-options', newEventColors);
Handlebars.registerPartial('event-attributes-input', eventAttributeInput);
Handlebars.registerPartial('view-event-details', viewEventDetails);
Handlebars.registerPartial('edit-event-form', editEventForm);
Handlebars.registerPartial('search-filters-form', searchFilterForm);
Handlebars.registerPartial('search-list-template', searchListTemplate);
Handlebars.registerPartial('search-list-item', searchListItemTemplate);
Handlebars.registerPartial('search-event-type-filters', searchEventTypeFiltersTemplate);

Handlebars.registerHelper('replace', function(find, replace, options){
    var string = options.fn(this);
    return string.replaceAll(find, replace);
})

Handlebars.registerHelper('isZero', function(value){
    return value === 0;
})

Handlebars.registerHelper('isEqual', function(value1, value2){
    return value1 === value2;
})