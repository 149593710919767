import {DEFAULT_YEAR} from "../constants";
import {getSelectedYear} from "./YearSlider";
import Handlebars from "handlebars";
import {FILTERS_UPDATED, ON_SEARCH_INPUT, ON_SEARCH_UNFOCUS, ON_YEAR_SET} from "../event-constants";

let appliedFilters = {eventTypes: {}, duration_start: DEFAULT_YEAR, duration_end: DEFAULT_YEAR};
let defaultFilters = {eventTypes: {}, duration_start: DEFAULT_YEAR, duration_end: DEFAULT_YEAR};

export function loadEventTypes(e){
    initializeEventTypeFilters(e.types);
    prepareEventTypesTemplate(e.types);

    $('.event-type-filter-checkbox').on('change', handleChangeSearchCriteria);
}

export function initializeAdvancedSearch(){
    appliedFilters.duration_start = getSelectedYear();
    appliedFilters.duration_end = getSelectedYear();

    // render form without event types
    prepareSearchFilterForm();

    // event listeners needed for this
    $('#search-filter').click(handleClickSearchFilterEvent);
    $('#search-duration-start').on('input', handleChangeStartDuration);
    $('#search-duration-end').on('input', handleChangeEndDuration);
    $('#close-advanced-search').click(handleCloseSearchFilter);
    $('#reset-advanced-search').click(handleResetSearchFilters);
    $(document).on(ON_YEAR_SET, handleYearSetEvent);
    $(document).on(FILTERS_UPDATED, handleFilterUpdate);
    $(document).on(ON_SEARCH_INPUT, handleSearchResultListOpen);
    $(document).on(ON_SEARCH_UNFOCUS, handleSearchResultListClosed);
}

/**
 * When the search result list is opened, move the dialog to the right of the result list
 */
function handleSearchResultListOpen(){
    $('#search-filters').addClass('shift-dialog-right');
}

/**
 * When the search result list is closed, move the dialog back to its default location
 */
function handleSearchResultListClosed(){
    $('#search-filters').removeClass('shift-dialog-right');
}

/**
 * When apply display filters, store them
 * @param e
 */
function handleFilterUpdate(e){
    appliedFilters.eventTypes = e.event_types;
}

function handleChangeStartDuration(e){
    appliedFilters.duration_start = $(e.target).val();
}

function handleChangeEndDuration(e){
    appliedFilters.duration_end = $(e.target).val();
}

/**
 * When the user clicks on the checkbox, we store the changes in staged filters
 * @param e event object
 */
function handleChangeSearchCriteria(e){
    let eventTypeID = $(e.target).val();
    appliedFilters.eventTypes[eventTypeID] = $(e.target).prop('checked');
}

/**
 * Initialize event types filters
 * @param eventTypes list of eventTypes
 */
function initializeEventTypeFilters(eventTypes){
    for(let eventType of eventTypes){
        appliedFilters.eventTypes[`${eventType.id}`] = true;
        defaultFilters.eventTypes[`${eventType.id}`] = true;
    }
}

/**
 * Prepare the data needed for the search filter dialog and create the template
 * @param eventTypes
 */
function prepareEventTypesTemplate(eventTypes){
    let template = Handlebars.compile("{{>search-event-type-filters}}");
    let htmlContent = template({eventTypes: eventTypes});

    $('#search-filter-event-types').html("");
    $('#search-filter-event-types').append(htmlContent);
}

/**
 * Prepare the data needed for the search filter dialog and create the template
 */
function prepareSearchFilterForm(){
    let template = Handlebars.compile("{{>search-filters-form}}");
    let htmlContent = template({duration_start: appliedFilters.duration_start, duration_end: appliedFilters.duration_end});

    $('#search-filter-dialog').html("");
    $('#search-filter-dialog').append(htmlContent);
}

/**
 * Show the search filter dialog
 */
function handleClickSearchFilterEvent(){
    $('#search-filters').removeClass('hidden');
    $('#search-duration-start').val(appliedFilters.duration_start);
    $('#search-duration-end').val(appliedFilters.duration_end);

    for(let eventType of Object.keys(appliedFilters.eventTypes)){
        let element = $(`#event-type-${eventType}-search-filter`);
        element.prop('checked', appliedFilters.eventTypes[eventType]);
    }
}

/**
 * Close the search filter dialog
 */
function handleCloseSearchFilter(){
    $('#search-filters').addClass('hidden');
}

/**
 * Handle resetting staged filters
 */
function handleResetSearchFilters(){
    appliedFilters = {...defaultFilters};

    for(let eventType of Object.keys(defaultFilters.eventTypes)){
        let element = $(`#event-type-${eventType}-search-filter`);
        element.prop('checked', defaultFilters.eventTypes[eventType]);
    }

    let selectedYear = getSelectedYear();
    $('#search-duration-start').val(selectedYear);
    $('#search-duration-end').val(selectedYear);
}

/**
 * Return currently applied filters
 * @returns {{year: number, eventTypes: {}}}
 */
export function getAppliedFilters(){
    return appliedFilters;
}

function handleYearSetEvent(e){
    appliedFilters.duration_start = e.year;
    appliedFilters.duration_end = e.year;
}