import {initializeEventTypes} from "./control/NewEvent";
import {initializeYearSlider} from "./control/YearSlider";
import {initializeFeaturePopup} from "./control/FeaturePopup";
import {handleInitializeFillSizeSlider} from "./control/FillSizeSlider";
import {initializeDiscardChangesDialog} from "./control/DiscardChanges";
import {initializeRecenterEvent} from "./control/RecenterEvent";
import {initializeAdvancedSearch} from "./control/AdvancedSearch";

initializeEventTypes();
initializeYearSlider();
initializeFeaturePopup();
handleInitializeFillSizeSlider();
initializeDiscardChangesDialog();
initializeRecenterEvent();
initializeAdvancedSearch();