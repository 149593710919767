import {tileGrid} from "./layers/Common";
import {eventLayer} from "./layers/Event"
import {drawLayer} from "./layers/Draw";
import {eventDisplayLayer} from "./layers/EventDisplay";
import Feature from "ol/Feature";
import Polygon from "ol/geom/Polygon";
import {MultiPolygon} from "ol/geom";
import {Fill, Stroke, Style} from "ol/style";
import {
    NEW_FEATURE_FILL_COLOR,
    DELETE_FEATURE_FILL_COLOR,
    DEFAULT_FEATURE_STROKE_COLOR, FEATURE_STROKE_WIDTH, FEATURE_DISPLAY_STROKE_WIDTH
} from "./constants";
import {handleTriggerEditMode, removeInteractions} from "./control/UserMode";
import {map} from "./Map";
import {FLAG_PAINT, ON_DRAW_END} from "./event-constants";
import {Draw} from "ol/interaction";
import {hideFeaturePopup, renderFeaturePopup} from "./control/FeaturePopup";
import {getBrushSize} from "./control/FillSizeSlider";

// list of all drawn on tiles
let tiles = {
    freeDraw: {}, // object containing all selected tiles
    features: {} // object containing features and their tiles + metadata
};

// tiles of current drawing
let currentDrawingTiles = {};

let draw = null;
let previousTile = null;
let firstCoordinate = null;

// clear all events from map
const clearEvents = () => {
    eventLayer.getSource().clear();
    drawLayer.getSource().clear();
    resetTiles();
}

function drawPolygonFromTiles(event, existingFeature = null) {
    let tiles = event.tiles
    let eventPolygon = new MultiPolygon([]);
    let tilesObject = {};
    for (let tile of tiles) {
        let polygon = getPolygonFromXYZ(tile.z, tile.x, tile.y)
        eventPolygon.appendPolygon(polygon.getGeometry());
        tilesObject[`${tile.z}-${tile.x}-${tile.y}`] = tile;
    }

    let eventObject = {...event};
    eventObject.tilesObject = tilesObject;
    eventPolygon.setProperties(eventObject);
    let feature = new Feature(eventPolygon.transform('EPSG:4326', 'EPSG:3857'));
    feature.setId(event.id);

    feature.setStyle(new Style({
        fill: new Fill({
            color: event.color_hex
        })
    }))

    feature.enableEditMode = enableEditMode;

    if(existingFeature){
        eventDisplayLayer.getSource().removeFeature(existingFeature);
    }

    eventDisplayLayer.getSource().addFeature(feature)
}

const zoomRatios = {
    1: 4.523561956,
    2: 6.820178962,
    3: 10.14210706,
    4: 12.1411492,
    5: 14.46301341
}

function getPolygonFromXYZ(z, x, y, color){
    const n = 2 ** zoomRatios[z];
    const left = (x / n) * 360 - 180;
    const right = ((x + 1) / n) * 360 - 180;
    const top = Math.atan(Math.sinh(Math.PI - (2 * Math.PI * y) / n)) * (180 / Math.PI);
    const bottom = Math.atan(Math.sinh(Math.PI - (2 * Math.PI * (y + 1)) / n)) * (180 / Math.PI);

    let feature = new Feature({
        geometry: new Polygon([
            [
                [left, bottom],
                [left, top],
                [right, top],
                [right, bottom],
                [left, bottom]
            ]
        ])
    });

    feature.getGeometry().setProperties({color})
    return feature;
}

// event highlighting functions
// Recursive function to create polygons for the lowest level of tiles
function createPolygons(z, x, y, color=NEW_FEATURE_FILL_COLOR) {
    // tiles is a hashmap, where the key is `${z}-${x}-${y}`, easy to search for this here, the tile details are in the value
    if(!(`${z}-${x}-${y}` in tiles.freeDraw)){
        tiles.freeDraw[`${z}-${x}-${y}`] = {z,x,y};
    }

    let polygon = getPolygonFromXYZ(z, x, y, color);

    polygon.getGeometry().transform('EPSG:4326', 'EPSG:3857');
    let features = eventLayer.getSource().getFeatures();

    let matchingFeature = features.find((feature) => {
        let extentMatch = JSON.stringify(feature.getGeometry().getExtent()) === JSON.stringify(polygon.getGeometry().getExtent());
        let flatCoordinateMatch = JSON.stringify(feature.getGeometry().getFlatCoordinates()) === JSON.stringify(polygon.getGeometry().getFlatCoordinates());
        return extentMatch && flatCoordinateMatch;
    });

    if(matchingFeature === undefined){
        eventLayer.getSource().addFeature(polygon);
    }
}

// delete tile from the map
function deletePolygons(z, x, y) {
    // Base case: zoom level 5 (or the maximum zoom level)
    if(`${z}-${x}-${y}` in tiles.freeDraw){
        delete tiles.freeDraw[`${z}-${x}-${y}`];
    }

    const polygon = getPolygonFromXYZ(z, x, y);

    polygon.getGeometry().transform('EPSG:4326', 'EPSG:3857');
    let features = eventLayer.getSource().getFeatures();

    let matchingFeature = features.find((feature) => {
        let extentMatch = JSON.stringify(feature.getGeometry().getExtent()) === JSON.stringify(polygon.getGeometry().getExtent());
        let flatCoordinateMatch = JSON.stringify(feature.getGeometry().getFlatCoordinates()) === JSON.stringify(polygon.getGeometry().getFlatCoordinates());
        return extentMatch && flatCoordinateMatch;
    });

    if(matchingFeature !== undefined){
        eventLayer.getSource().removeFeature(matchingFeature);
    }
}

// function to delete a tile, check if the tile being selected is a new tile or an old tile
function handleDeleteTile(z, x, y){
    // logic, if tile has new in its property, just delete it
    if(`${z}-${x}-${y}` in tiles.freeDraw && tiles.freeDraw[`${z}-${x}-${y}`].new){
        deletePolygons(z, x, y);
    }else if(`${z}-${x}-${y}` in tiles.freeDraw){
        // if not, try change it color to red and mark it for deletion in the tiles map
        deletePolygons(z, x, y);
        createPolygons(z, x, y, DELETE_FEATURE_FILL_COLOR)
        tiles.freeDraw[`${z}-${x}-${y}`].delete = true;
    }
}

// function to select a tile, checks if the tile being selected is a new tile or an old tile
function handleSelectTile(z, x, y){
    if(!(`${z}-${x}-${y}` in tiles.freeDraw)){
        createPolygons(z, x, y)
        tiles.freeDraw[`${z}-${x}-${y}`].new = true
    }else if(tiles.freeDraw[`${z}-${x}-${y}`].delete){
        deletePolygons(z, x, y);
        createPolygons(z, x, y, DEFAULT_FEATURE_STROKE_COLOR);
    }
}

// general function to mark if the tile should be selected or deleted
function handleTile(e) {
    let fillSize = getBrushSize();
    let tileCoord = tileGrid.getTileCoordForCoordAndZ(e.originalEvent.coordinate, fillSize - 1);

    let pointerButton = e.originalEvent.originalEvent.buttons;

    if (pointerButton === 1) {
        handleSelectTile(tileCoord[0] + 1, tileCoord[1], tileCoord[2]);
    } else if (pointerButton === 2) {
        handleDeleteTile(tileCoord[0] + 1, tileCoord[1], tileCoord[2]);
    }
}

// when the user is clicking on the map, handle selecting the tile
function handleTileClick(e){
    handleTile(e);
}

// when the user is dragging the mouse across the map, handle selecting the tiles
function handleTileDrag(e){
    if(e.originalEvent.dragging) {
        handleTile(e);
    }
}

// Bresenham's line algorithm implementation
function plotLineLow(xMin, yMin, xMax, yMax){
    let dx = xMax - xMin;
    let dy = yMax - yMin;
    let yi = 1

    if(dy < 0){
        yi = -1;
        dy = -dy;
    }

    let D = (2 * dy) - dx;
    let y = yMin;

    let coordinates = {};

    for(let i=xMin; i<=xMax; i++){
        let coordinate = [i, y];
        if(!(`${coordinate[0]}${coordinate[1]}` in coordinates)){
            coordinates[`${coordinate[0]}${coordinate[1]}`] = coordinate;
        }

        if(D > 0){
            y = y + yi;
            D = D + (2*(dy - dx));
        }else{
            D = D + 2*dy;
        }
    }

    return Object.values(coordinates).map(b => [4, b[0], b[1]]);
}

// Bresenham's line algorithm implementation
function plotLineHigh(xMin, yMin, xMax, yMax){
    let dx = xMax - xMin;
    let dy = yMax - yMin;
    let xi = 1;

    if(dx < 0){
        xi = -1;
        dx = -dx;
    }

    let D = (2 * dx) - dy;
    let x = xMin;

    let coordinates = {};

    for(let i=yMin; i<=yMax; i++){
        let coordinate = [x, i];
        if(!(`${coordinate[0]}${coordinate[1]}` in coordinates)){
            coordinates[`${coordinate[0]}${coordinate[1]}`] = coordinate;
        }

        if(D > 0){
            x = x + xi;
            D = D + (2*(dx - dy));
        }else{
            D = D + 2*dx;
        }
    }

    return Object.values(coordinates).map(b => [4, b[0], b[1]]);
}

// Bresenham's line algorithm implementation
function plotLine(xMin, yMin, xMax, yMax){
    if (Math.abs(yMax - yMin) < Math.abs(xMax - xMin)) {
        if (xMin > xMax) {
            return plotLineLow(xMax, yMax, xMin, yMin);
        } else {
            return plotLineLow(xMin, yMin, xMax, yMax);
        }
    } else {
        if (yMin > yMax) {
            return plotLineHigh(xMax, yMax, xMin, yMin);
        } else {
            return plotLineHigh(xMin, yMin, xMax, yMax);
        }
    }
}

// general function to reset the tiles object
function resetTiles(){
    tiles = {
        freeDraw: {},
        features: {}
    };
}

// set tiles when editing an event
function setTiles(newTiles){
    tiles.freeDraw = newTiles;
}

// start the drawing interaction
export function handlePaintLine(){
    removeInteractions();

    if(map.get(FLAG_PAINT) === 'false' || map.get(FLAG_PAINT) === undefined){
        addInteraction();
        map.set(FLAG_PAINT, 'true', true);
        $('#paint-line').prop('selected', true);
    }
}

// set up drawing interaction on the map, and all the event listeners needed when drawing
function addInteraction(){
    draw = new Draw({
        source: drawLayer.getSource(),
        type: "Polygon",
        geometryFunction: (coords, geom) => {
            if (!geom) {
                geom = new Polygon([]);
            }
            geom.setCoordinates(coords);
            return geom.simplify();
        },
        freehand: true,
        style: {
            'stroke-color': DEFAULT_FEATURE_STROKE_COLOR,
            'stroke-width': FEATURE_STROKE_WIDTH,
        }
    });

    map.addInteraction(draw);

    $(draw).on('drawstart', () => {
        resetDrawingData();
        hideFeaturePopup();
        $(map).on('pointermove', handlePointerMove)
    });

    $(draw).on('drawend', (e) => {
        $(map).off('pointermove', handlePointerMove)
        e.originalEvent.feature.setId(e.originalEvent.feature.ol_uid);

        addGeometryXYZCoordinate(firstCoordinate, previousTile);

        e.originalEvent.feature.renderFeaturePopup = renderFeaturePopup;

        let featureId = e.originalEvent.feature.getId();
        let featureCoordinate = [e.originalEvent.feature.getGeometry().flatCoordinates[0], e.originalEvent.feature.getGeometry().flatCoordinates[1]]
        let pixels = map.getPixelFromCoordinate(featureCoordinate);

        tiles.features[featureId] = {'tiles': Object.values(currentDrawingTiles), 'fill': false}

        $(document).trigger($.Event(ON_DRAW_END, {featureId: featureId, x: pixels[0], y: pixels[1]}));

        resetDrawingData();
    });

    $(draw).on('drawabort', () => {
        resetDrawingData();
    })
}

function enableEditMode(featureId){
    let feature = eventDisplayLayer.getSource().getFeatureById(featureId);
    feature.setStyle(null);
    feature.getGeometry().setProperties({color: DEFAULT_FEATURE_STROKE_COLOR})
    // enable edit mode
    handleTriggerEditMode();
    eventLayer.getSource().addFeature(feature);
    resetTiles();
    setTiles(feature.getGeometry().getProperties().tilesObject);
}

// mark feature to be filled
export function fillFeature(featureId){
    tiles.features[featureId].fill = true;
}

// remove the feature from our data
export function undoFeature(featureId){
    delete tiles.features[featureId];
}

// check if there are features present in the state
export function areFeaturesPresent(){
    return Object.keys(tiles.freeDraw).length > 0 || Object.keys(tiles.features).length > 0;
}

// event handler for when the pointer is moving when drawing, capture position of mouse and store the tiles underneath
function handlePointerMove(e) {
    if(firstCoordinate == null){
        firstCoordinate = e.originalEvent.coordinate;
    }

    previousTile = addGeometryXYZCoordinate(e.originalEvent.coordinate, previousTile)
}

// convert drawing coordinate to tiles, and if needed interpolate tiles between last known tile and current tile
function addGeometryXYZCoordinate(coordinate, previousTile){

    let tileCoord =  tileGrid.getTileCoordForCoordAndZ(coordinate, 4);

    let tileCoords = [tileGrid.getTileCoordForCoordAndZ(coordinate, 4)];

    if(tileCoord !== previousTile && previousTile != null){
        // calculate min and max coordinates if not the same
        let xMin, xMax, yMin, yMax

        xMin = previousTile[1]
        xMax = tileCoord[1]
        yMin = previousTile[2]
        yMax = tileCoord[2]

        tileCoords = [...tileCoords, ...plotLine(xMin, yMin, xMax, yMax)]
    }

    for(let tileCoord of tileCoords){
        if(!(`${tileCoord[0]+1}-${tileCoord[1]}-${tileCoord[2]}` in currentDrawingTiles)){
            currentDrawingTiles[`${tileCoord[0]+1}-${tileCoord[1]}-${tileCoord[2]}`] = {z: tileCoord[0]+1,x: tileCoord[1],y: tileCoord[2]}
        }
    }

    return tileCoord;
}

// remove draw interaction from map, and reset the variable
function removeMapInteractions(){
    map.removeInteraction(draw);
    draw = null;
}

// turn off paint interaction on the map
export function handlePaintOff() {
    removeMapInteractions();
    map.set(FLAG_PAINT, 'false', true);
    $('#paint-line').prop('selected', false);
}

// reset data related to drawing
function resetDrawingData(){
    previousTile = null;
    firstCoordinate = null;
    currentDrawingTiles = {};
}

export {tiles}
export {clearEvents, handleTileClick, handleTileDrag, drawPolygonFromTiles}