import {eventTypes, getEventTypeID} from "../control/NewEvent";
import {callAPI} from "../model/Request";
import {prepareEventAttributesForm, editor} from "./EventAttributesModal";
import {ON_CREATE_EVENT_FAIL, ON_CREATE_EVENT_SUCCESS} from "../event-constants";
import {tiles} from "../features";
import {SELECTED_LANGUAGE_CODE} from "../constants";

export async function renderNewEventModal(){
    let templateObject = await prepareNewEventTemplate();
    prepareEventAttributesForm(templateObject);
}

// get all event attributes for selected event type and
async function getAttributesData(){
    let eventTypeID = getEventTypeID();
    return await callAPI('get-event-type-attributes', null, [eventTypeID])
        .then((result) => result)
}

// Prepare the data for the new event form
async function prepareNewEventTemplate() {
    let attributes = await getAttributesData();

    let selectedColor = AVAILABLE_COLORS.find((color) => color.isDefault).value;

    let yearInputElement = $('#year-input');

    let durationStartValue = yearInputElement.val();
    let durationEndValue = yearInputElement.val();

    if ($('#insert-event-details').length > 0) {
        durationStartValue = $('#duration-start').val();
        durationEndValue = $('#duration-end').val();
    }

    let eventTypeName = getEventTypeName();

    return {
        selectedColor: selectedColor,
        colors: AVAILABLE_COLORS,
        attributes: attributes,
        modalHeader: `Create New ${eventTypeName}`,
        durationStartValue: durationStartValue,
        durationEndValue: durationEndValue,
        modalID: 'insert-event-details',
        description: null
    }
}

// look for the event name within the event types array
function getEventTypeName(){
    let eventTypeId = getEventTypeID();
    let eventType = eventTypes.find((eventType) => eventType.id == eventTypeId);
    return eventType.name;
}

// build attribute object to send to server
function handleEventAttributes(){
    let attributeElements = $('.attribute-input')
    let attributeObject = {};

    // get attribute data and build its object to return
    Array.from(attributeElements).forEach((attributeElement) => {
        let value = attributeElement.value === null ? "" : attributeElement.value;
        attributeObject[attributeElement.id] = {
            [SELECTED_LANGUAGE_CODE]: value,
            type: attributeElement.type
        }
    })

    return attributeObject;
}

export function handleSaveEvent(){
    // get the description and event type for this event
    let eventDescription = editor.getData();
    let eventTypeId = getEventTypeID();

    // get attribute data for this event
    let attributeObject = handleEventAttributes();

    let newEvent = {
        'type': eventTypeId,
        'description': eventDescription,
        'tiles': Object.values(tiles.freeDraw),
        'geometries': Object.values(tiles.features),
        'attributes': attributeObject
    }

    // parse through all input fields for this event
    let fields = {
        'name': 'event-name',
        'duration_start': 'duration-start',
        'duration_end': 'duration-end',
        'color_hex': 'create-event-color'
    }

    Object.entries(fields).forEach(([name, field]) => {
        newEvent[name] = $(`#${field}`).val();
    })

    callAPI('create-new-event', newEvent, [])
        .then(()=>{
            $(document).trigger(ON_CREATE_EVENT_SUCCESS);
        })
        .catch(() => {
            $(document).trigger(ON_CREATE_EVENT_FAIL)
        })
}