import VectorSource from "ol/source/Vector";
import {drawPolygonFromTiles} from "../features";
import VectorImageLayer from "ol/layer/VectorImage";
import {tileGrid, zoomRatios} from "./Common";
import {getEventTypeFilters} from "../control/FilterEvents";
import {getSelectedYear} from "../control/YearSlider";
import {callAPI} from "../model/Request";
import {ON_LAYER_LOADED, ON_LAYER_LOADING} from "../event-constants";
import {DEFAULT_ZOOM_LEVEL} from "../constants";

let currentZoomLevel = DEFAULT_ZOOM_LEVEL;

const eventDisplaySource = new VectorSource({
    loader: reloadLayer,
    tileGrid: tileGrid,
    overlaps: false,
})

export const eventDisplayLayer = new VectorImageLayer({
    source: eventDisplaySource,
    visible: true,
})

// Function to determine the zoom level based on the current zoom level
function getZoomLevel(zoomLevel) {
    for (let i = 0; i < zoomRatios.length; i++) {
        if (zoomLevel < zoomRatios[i]) {
            return i + 1; // Return the corresponding zoom level
        }
    }
    // If current zoom is greater than the last ratio, return the max level
    return zoomRatios.length;
}

export function onZoomChange(newZoomLevel){
    let newZoomRounded = getZoomLevel(newZoomLevel)
    if(currentZoomLevel !== newZoomRounded){
        currentZoomLevel = newZoomRounded;

        // only if the layer is visible we refresh the data
        if(eventDisplayLayer.isVisible()){
            reloadLayer();
        }
    }
}

// function to get events from API and display on the map
export function reloadLayer(year){
    $(document).trigger(ON_LAYER_LOADING);
    let eventTypes = getEventTypeFilters();
    // let searchTerm = $('#autoComplete').val();
    if(year == null || typeof year !== "number"){
        year = getSelectedYear();
    }
    callAPI('get-events-by-year', null, [year, currentZoomLevel, eventTypes])
        .then((results) => {
            // create and populate a map of all existing features
            let existingFeatures = {};
            eventDisplaySource.getFeatures().forEach(feature => {
                existingFeatures[feature.getId()] = feature;
            })

            for(let event of results){
                if(event.id in existingFeatures){
                    let existingFeature = existingFeatures[event.id]
                    drawPolygonFromTiles(event, existingFeature);
                    delete existingFeatures[event.id]
                }else{
                    drawPolygonFromTiles(event);
                }
            }

            // any feature still left should be deleted from the map.
            Object.values(existingFeatures).forEach((feature) => {
                eventDisplaySource.removeFeature(feature);
            })

            $(document).trigger(ON_LAYER_LOADED);
        })
}

export function handleYearSetEvent(e){
    reloadLayer(e.year);
}