import {maxZoom, minZoom} from "../layers/Common";
import {DEFAULT_BRUSH_SIZE} from "../constants";

let brushSize = DEFAULT_BRUSH_SIZE;

export function handleInitializeFillSizeSlider(){
    const slider = $('#fill-size-slider');

    // setting range extent and default value for slider
    slider.attr('min', `${minZoom+1}`);
    slider.attr('max', `${maxZoom}`);
    slider.attr('value', `${maxZoom}`);

    slider.on('change', handleSliderChange);
}

export function hideSlider(){
    // hide slider
    let brushSlider = $('#fill-size-slider');
    brushSlider.addClass('hidden');
    brushSlider.removeClass('show');
}

export function showSlider(){
    // show slider
    let brushSlider = $('#fill-size-slider');
    brushSlider.addClass('show');
    brushSlider.removeClass('hidden');
}

function handleSliderChange(e){
    brushSize = e.target.value;
}

export function getBrushSize(){
    return brushSize;
}