import {map} from "../Map";
import {handleTileClick, handleTileDrag} from "../features";
import {
    FLAG_SELECT_TILE
} from "../event-constants";
import {removeInteractions} from "./UserMode";
import {hideSlider, showSlider} from "./FillSizeSlider";

export function handleSelectTileOn() {
    removeInteractions();

    if (map.get(FLAG_SELECT_TILE) === 'false' || map.get(FLAG_SELECT_TILE) === undefined) {
        $(map).on('pointerdown', handleTileClick);
        $(map).on('pointerdrag', handleTileDrag);
        map.set(FLAG_SELECT_TILE, 'true');
        $('#select-tile').prop('selected', true);

        // show slider when brush is clicked
        showSlider();
    }
}

export function handleSelectTileOff(){
    $(map).off('pointerdown', handleTileClick);
    $(map).off('pointerdrag', handleTileDrag);
    map.set(FLAG_SELECT_TILE, 'false');
    $('#select-tile').prop('selected', false);

    // hide slider when brush is off
    hideSlider()
}