export const ENGLISH_LANGUAGE_CODE = "en-US";
export const SELECTED_LANGUAGE_CODE = ENGLISH_LANGUAGE_CODE;

export const DEFAULT_FEATURE_STROKE_COLOR = "rgba(255,204,51,1)"; // #FFCC33
export const DEFAULT_FEATURE_FILL_COLOR = "rgba(255,255,51,0.5)"; // #FFCC33
export const NEW_FEATURE_FILL_COLOR = "rgba(23,255,0,1)"; // #17FF00
export const DELETE_FEATURE_FILL_COLOR = "rgba(255,79,79,1)"; // #FF4F4F
export const FEATURE_STROKE_WIDTH = 3;
export const FEATURE_LAYER_OPACITY = 0.5;

export const ATTRIBUTE_LABELS = {
    'Name': 'Name',
    'Description': 'Description',
    'Year': 'Year'
}

export const RICH_TEXT_FIELD = 'rich-text';
export const PLAIN_TEXT_FIELD = 'plain-text';

export const DEFAULT_YEAR = new Date().getFullYear();
export const DEFAULT_BRUSH_SIZE = 5;

export const FOCUSED_FEATURE = {padding: [500,500,500,500]};
export const FOCUSED_FEATURE_DURING_VIEW = {padding: [500, 200, 500, 1000]};

export const DEFAULT_ZOOM_LEVEL = 1;