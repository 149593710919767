import '@material/web/iconbutton/filled-icon-button';
import '@material/web/icon/icon';
import '@material/web/iconbutton/outlined-icon-button';
import '@material/web/fab/fab';
import '@material/web/button/outlined-button';
import '@material/web/button/filled-button';
import '@material/web/button/text-button';
import {styles as typescaleStyles} from '@material/web/typography/md-typescale-styles.js';
import '@material/web/slider/slider';
import '@material/web/menu/menu.js';
import '@material/web/menu/menu-item.js';
import '@material/web/dialog/dialog';
import '@material/web/checkbox/checkbox';
import '@material/web/iconbutton/icon-button';
import '@material/web/divider/divider';
import '@material/web/textfield/outlined-text-field';
import '@material/web/select/outlined-select';
import '@material/web/select/select-option';
import '@material/web/progress/circular-progress';
import '@material/web/textfield/filled-text-field';

// `typescaleStyles.styleSheet` is a `CSSStyleSheet` that can be added to a
// document or shadow root's `adoptedStyleSheets` to use the `.md-typescale-*`
// classes.
document.adoptedStyleSheets.push(typescaleStyles.styleSheet);