import {map} from "../Map";
import {
    FLAG_SELECT_TILE,
    FLAG_PAINT
} from "../event-constants";
import {handlePointerButtonOff} from "./PointerControl";
import {handleSelectTileOff} from "./SelectTile";
import {handlePaintOff} from "../features";
import {disableYearInput, enableYearInput} from "./YearSlider";
import {hideFeaturePopup} from "./FeaturePopup";
import {handleHideRecenterButton} from "./RecenterEvent";

// show controls for view mode
export function handleEnableViewMode(){
    let default_controls = $('#default-controls');

    default_controls.removeClass('hidden');
    $('.event-search').removeClass('hidden');
    enableYearInput();
    handleHideRecenterButton();
}

// hide controls for view mode
export function handleDisableViewMode(){
    let default_controls = $('#default-controls');

    default_controls.addClass('hidden');
    $('.event-search').addClass('hidden');
}

// show controls for edit mode
export function handleEnableEditMode(){
    let edit_controls = $('#event-edit-controls');

    edit_controls.removeClass('hidden');

    let save_controls = $('.floating-controls');
    save_controls.removeClass('hidden');

    disableYearInput();
}

// hide controls for edit mode
export function handleDisableEditMode(){
    let edit_controls = $('#event-edit-controls');

    edit_controls.addClass('hidden');

    let save_controls = $('.floating-controls');
    save_controls.addClass('hidden');
    handleHideRecenterButton();
}

// function to change state of UI to view mode
export function handleTriggerViewMode(){
    handleEnableViewMode();
    handleDisableEditMode();
}

// function to change state of UI to edit mode
export function handleTriggerEditMode(){
    handleEnableEditMode();
    handleDisableViewMode();
}

// handle showing the delete confirmation menu
export function handleToggleDeleteConfirmMenu(){
    $('#delete-confirmation').prop('open', true);

    handleDisableEditMode();
    handleDisableViewMode();
}

// handle closing the delete confirmation menu
export function handleCloseDeleteConfirmMenu(){
    $('#delete-confirmation').prop('open', false);

    handleEnableViewMode();
    handleDisableEditMode();
}

// function for removing all edit mode controls from the map
export function removeInteractions(){
    if (map.get(FLAG_SELECT_TILE) === 'true'){
        // call function to turn off select tile interaction
        handleSelectTileOff()
    } else if (map.get(FLAG_PAINT) === 'true'){
        // call function to turn off drawing interaction
        handlePaintOff()
    }

    hideFeaturePopup();
    handlePointerButtonOff();
}