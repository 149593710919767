import {drawLayer} from "../layers/Draw";
import {map} from "../Map";
import {removeInteractions} from "./UserMode";
import {hideFeaturePopup} from "./FeaturePopup";
import {tiles} from "../features";

// enable pointer
export function handlePointerButtonClicked(){
    removeInteractions();
    $('#pointer-button').prop('selected', true);
    $(map).on('click', handlePointerOnClick);
}

export function handlePointerButtonOff(){
    $(map).off('click', handlePointerOnClick);
    $('#pointer-button').prop('selected', false);
}

function handlePointerOnClick(e){
    // check where on the map we pressed
    // check if where we pressed is within a drawing
    const features = drawLayer.getSource().getFeatures();
    let featureFound = false;
    for(let feature of features){
        let coordinate = e.originalEvent.coordinate;
        if(feature.getGeometry().intersectsCoordinate(coordinate)){
            // render the popup
            featureFound = true;
            let featureId = feature.getId();
            let [x, y] = e.originalEvent.pixel;
            feature.renderFeaturePopup(featureId, x, y, tiles.features[featureId].fill);
        }
    }

    if(!featureFound){
        hideFeaturePopup();
    }
}