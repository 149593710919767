import {eventDisplayLayer} from "../../layers/EventDisplay";
import {eventLayer} from "../../layers/Event";
import {clearEvents, drawPolygonFromTiles, tiles} from "../../features";
import {ON_ERROR_SAVING_TILES, ON_SAVE_SUCCESS} from "../../event-constants";
import {callAPI} from "../../model/Request";


function closeViewEventHandler(){
    eventDisplayLayer.setVisible(true);

    clearEvents();
}

export function handleUpdateEvent(featureId, eventData){
    let tileDelta = {
        'delete': Object.values(tiles.freeDraw).filter(tile=>tile.delete),
        'new': Object.values(tiles.freeDraw).filter(tile=>tile.new)
    }

    let updatedEvent = {
        'id': eventData.id,
        'name': eventData.name,
        'event_group_id': eventData.event_group_id,
        'tiles': tileDelta
    }

    callAPI('edit-event', updatedEvent, [])
        .then(()=>{
            // no refresh, remove the geometry and reload it, get it and add it back as a feature
            refreshEvent(featureId);

            eventLayer.getSource().clear();
            $(document).trigger(ON_SAVE_SUCCESS);
        })
        .catch(()=>{
            $(document).trigger(ON_ERROR_SAVING_TILES);
        })

    closeViewEventHandler();
}

export function refreshEvent(featureId){
    callAPI('get-event-by-id',null,[featureId])
        .then((eventFromDB) => {
            let feature = eventDisplayLayer.getSource().getFeatureById(featureId);
            eventDisplayLayer.getSource().removeFeature(feature);
            drawPolygonFromTiles(eventFromDB)
        })
}