import {ON_YEAR_SET} from "../event-constants";
import {DEFAULT_YEAR} from "../constants";

let year = DEFAULT_YEAR;

// Set up year slider and all its event listeners
export function initializeYearSlider(){
    enableYearInput();

    // year slider event handlers
    let yearSlider = $('#year-slider');
    yearSlider.on('input', handleSliderInput);
    yearSlider.on('change', handleSliderChanged);
    $('#year-input').on('change', handleYearChanged);
    $(document).on(ON_YEAR_SET, handleYearSetEvent);
}

function handleYearSetEvent(e){
    $('#year-slider').val(e.year);
    $('#year-input').val(e.year);
}

// as the input changes, change the year input textfield to match the value
function handleSliderInput(e){
    $('#year-input').val(e.target.value);
}

// after year input value has lost focus, reload the map
function handleYearChanged(e){
    year=e.target.value;
    $(document).trigger($.Event(ON_YEAR_SET, {year: year}));
}

// after slider has lost focus, reload the map
function handleSliderChanged(e){
    year=e.target.value;
    $(document).trigger($.Event(ON_YEAR_SET, {year: year}));
}

export function disableYearInput(){
    $('#year-slider').prop('disabled', true);
    $('#year-input').prop('disabled', true);
}

export function enableYearInput(){
    $('#year-slider').prop('disabled', false);
    $('#year-input').prop('disabled', false);
}

export function getSelectedYear(){
    return year;
}