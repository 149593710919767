import Map from "ol/Map";
import View from "ol/View";
import {defaults as defaultInteractions} from "ol/interaction";
import {eventDisplayLayer, onZoomChange} from "./layers/EventDisplay";
import {backgroundLayer} from "./layers/Background"
import {drawLayer} from "./layers/Draw";
import {eventLayer} from "./layers/Event";
import {ON_MAP_CLICK_EVENT} from "./event-constants";

// controls for the map
const controls = [
]

// layers we use on the map
const layers = [
    backgroundLayer,
    eventLayer,
    drawLayer,
    eventDisplayLayer
]

// the map view object
const view = new View({
    center: [0, 0],
    zoom: 2,
    constrainResolution: false,
})

const map = new Map({
    target: "map",
    controls: controls,
    interactions: defaultInteractions({shiftDragZoom: false}),
    layers: layers,
    view: view
});

map.getView().on('change:resolution', () => {
    onZoomChange(map.getView().getZoom());
})

// Handle when the user clicks on the map
export function handleMapClicked(e){
    if(eventDisplayLayer.isVisible()){
        // check if any feature is under event
        let feature = eventDisplayLayer.getSource().getFeaturesAtCoordinate(e.originalEvent.coordinate);
        if(feature.length > 0){
            // trigger event to render to view event modal
            $(document).trigger($.Event(ON_MAP_CLICK_EVENT, {feature: feature[0]}));
        }
    }
}

export {map};