const resourceAPIs = {
    'get-events-by-year': ([year, zoom, types, searchTerm]) => {
        let url = `/api/events/${year}/${zoom}`
        let params = new URLSearchParams();

        if(types !== undefined && types.length > 0){
            let typesString = types.join(',')
            params.append('event_type', typesString);
        }

        if(searchTerm !== undefined && searchTerm.length > 0){
            params.append('search', searchTerm);
        }

        url = `${url}?${params.toString()}`;
        return new Request(url);
    },
    'get-event-by-id': ([eventID]) => {
        return new Request(`/api/events/${eventID}`);
    },
    'create-new-event': (params, body) => {
        return new Request('/api/events', getHeaders('POST', body));
    },
    'edit-event': (params, body) => {
        return new Request('/api/events', getHeaders('PUT', body));
    },
    'delete-event': ([eventID], body=null) => {
        return new Request(`/api/events/${eventID}`, getHeaders('DELETE', body));
    },
    'search-event-autocomplete': (params=null, body) => {
        let url = '/api/search';
        return new Request(`${url}`, getHeaders('POST', body));
    },
    'get-event-types': () => {
        return new Request('/api/types');
    },
    'get-event-type-attributes': ([eventTypeID]) => {
        return new Request(`/api/types/${eventTypeID}/attributes`);
    }
}

export function getURL(urlId, params, body){
    return resourceAPIs[urlId](params, body);
}

function getHeaders(method, body){
    let headers = {
        method: method,
        headers: {
            "Content-Type": "application/json",
        }
    }

    // Only include body in the fetch call if it's not null and the method allows it
    if (body !== null && (method === 'POST' || method === 'PUT')) {
        headers.body = JSON.stringify(body);
    }

    return headers
}