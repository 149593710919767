import {eventDisplayLayer} from "../layers/EventDisplay";
import {clearEvents} from "../features";
import {
    ATTRIBUTE_LABELS, FOCUSED_FEATURE_DURING_VIEW,
    PLAIN_TEXT_FIELD, RICH_TEXT_FIELD,
    SELECTED_LANGUAGE_CODE,
} from "../constants";
import Handlebars from "handlebars";
import {
    ON_CLOSE_VIEW_EVENT,
    ON_CONFIRM_DELETE_EVENT,
    ON_DELETE_EVENT, ON_EDIT_ATTRIBUTES_PRESSED,
    ON_EDIT_TILES_PRESSED,
    ON_EVENT_CLICKED
} from "../event-constants";
import {callAPI} from "../model/Request";
import {map} from "../Map";

let featureId;

// prepare the data for this events attributes
function prepareEventAttributes(eventTypeAttributes){
    if (eventTypeAttributes === null){
        return;
    }

    let attributes = [];

    Object.keys(eventTypeAttributes).forEach((name) => {
        if(eventTypeAttributes[name][SELECTED_LANGUAGE_CODE] !== ""){
            attributes.push({attributeName: name, attributeValue: eventTypeAttributes[name][SELECTED_LANGUAGE_CODE], attributeType: eventTypeAttributes[name].type});
        }
    })

    return attributes;
}

// prepare all event attributes to show to a user
function prepareViewEventData(event){
    // prepare list of objects 'attributes' with attributeName and attributeValue keys for each datapoint
    let attributes = [];

    attributes.push({attributeName: ATTRIBUTE_LABELS['Name'], attributeValue: event.name, attributeType: PLAIN_TEXT_FIELD});
    attributes.push({attributeName: ATTRIBUTE_LABELS['Description'], attributeValue: event.description, attributeType: RICH_TEXT_FIELD});
    let date = new Date(event.date);
    let year = date.getFullYear();
    attributes.push({attributeName: ATTRIBUTE_LABELS['Year'], attributeValue: year, attributeType: PLAIN_TEXT_FIELD});

    attributes = [...attributes, ...prepareEventAttributes(event.attributes)];

    let template = Handlebars.compile('{{>view-event-details}}')
    let htmlContent = template({attributes: attributes});
    $('#event-details-template').html(htmlContent);
}

// Sync any data needed for actions to be performed on this event
function prepareViewEventActions(currentFeatureId){
    featureId = currentFeatureId;
}

// prepare all the data and change the state to view event details
export function renderViewEventModal(e){
    let feature = e.feature;
    let eventData = feature.getGeometry().getProperties();
    let featureId = feature.getId();

    // create buttons for event viewing
    prepareViewEventActions(featureId);
    prepareViewEventData(eventData);

    $('#edit-event-attributes').click(() => $(document).trigger(ON_EDIT_ATTRIBUTES_PRESSED));
    $('#delete-event').click(() => $(document).trigger(ON_DELETE_EVENT));
    $('#close-view-event-sidebar').click(() => $(document).trigger(ON_CLOSE_VIEW_EVENT));
    $('#edit-event-tiles').click(() => $(document).trigger(ON_EDIT_TILES_PRESSED));
    $('#center-event').click(handleCenterEvent);

    $(document).trigger(ON_EVENT_CLICKED);
}

function handleCenterEvent(){
    let selectedFeature = eventDisplayLayer.getSource().getFeatureById(featureId);
    let extent = selectedFeature.getGeometry().getExtent();
    map.getView().fit(extent, FOCUSED_FEATURE_DURING_VIEW);
}

// handling closing the view event details modal, happens after transitioning out of view event details
export function closeViewEventHandler(){
    eventDisplayLayer.setVisible(true);

    // remove all attributes related to this event
    clearEvents();
    $('#view-event-details').addClass('hidden');
}

// handling deletion of event after confirmation
export function deleteEventHandler(){
    let feature = eventDisplayLayer.getSource().getFeatureById(featureId);

    callAPI('delete-event', null, [featureId])
        .then((result) => {
            eventDisplayLayer.getSource().removeFeature(feature);
            closeViewEventHandler();
            eventDisplayLayer.getSource().refresh();
            $(document).trigger(ON_CONFIRM_DELETE_EVENT);
        })
}

// function that is called when edit tile has been clicked
export function editTileHandler(){
    eventDisplayLayer.setVisible(false);

    let featureId = getFeatureId();

    eventDisplayLayer.getSource().getFeatureById(featureId).enableEditMode(featureId);
}

export function getFeatureId(){
    return featureId;
}