import {handleSearchInputKeyDown, handleSelectEvent} from "./control/SearchEvents";
import {
    handleEventTypeFilter,
} from "./control/FilterEvents";
import {
    ON_ADD_EVENT_PRESSED,
    ON_CANCEL_DELETE_EVENT,
    ON_CANCEL_DISCARD_NEW_EVENT,
    ON_CANCEL_INSERT_NEW_EVENT,
    ON_CLOSE_VIEW_EVENT,
    ON_CONFIRM_DELETE_EVENT,
    ON_CONFIRM_DISCARD_CHANGES_TO_TILES,
    ON_CONFIRM_DISCARD_EVENT_ATTRIBUTE_CHANGES,
    ON_CONFIRM_DISCARD_NEW_EVENT,
    ON_CREATE_EVENT_FAIL,
    ON_CREATE_EVENT_SUCCESS,
    ON_CREATE_SAVE_PRESSED,
    ON_DELETE_EVENT,
    ON_DISCARD_CHANGES,
    ON_DRAW_END,
    ON_EDIT_ATTRIBUTES_PRESSED,
    ON_EDIT_TILES_PRESSED, ON_ERROR_SAVING_TILES,
    ON_EVENT_CLICKED,
    ON_EVENT_TYPES_READY,
    ON_FILL_DRAWING,
    ON_FREE_FORM_BUTTON_PRESSED, ON_LAYER_LOADED, ON_LAYER_LOADING, ON_MAP_CLICK_EVENT,
    ON_SAVE_ATTRIBUTES_PRESSED,
    ON_SAVE_PRESSED, ON_SAVE_SUCCESS, ON_YEAR_SET
} from "./event-constants";
import {handleSelectTileOn} from "./control/SelectTile";
import {handlePaintLine} from "./features";
import {
    handleOnDiscardCancelled,
    handleSaveButtonPressed,
    setState
} from "./state/transitions";
import {handlePointerButtonClicked} from "./control/PointerControl";
import {
    handleEventTypeMenu,
} from "./control/NewEvent";
import {handleMapClicked, map} from "./Map";
import {deleteEventHandler, renderViewEventModal} from "./events/ViewEvent";
import {handleYearSetEvent} from "./layers/EventDisplay";
import {loadEventTypes} from "./control/AdvancedSearch";

$(document).on(ON_YEAR_SET, handleYearSetEvent);

$(document).on(ON_EVENT_TYPES_READY, handleEventTypeMenu)
$(document).on(ON_EVENT_TYPES_READY, handleEventTypeFilter)
$(document).on(ON_EVENT_TYPES_READY, loadEventTypes);

// state change listeners
$(document).on(ON_ADD_EVENT_PRESSED, setState);
$(document).on(ON_CREATE_EVENT_SUCCESS, setState);
$(document).on(ON_CREATE_EVENT_FAIL, setState);
$(document).on(ON_CREATE_SAVE_PRESSED, setState);
$(document).on(ON_SAVE_PRESSED, setState);
$(document).on(ON_CONFIRM_DISCARD_NEW_EVENT, setState);
$(document).on(ON_CONFIRM_DISCARD_CHANGES_TO_TILES, setState);
$(document).on(ON_CONFIRM_DISCARD_EVENT_ATTRIBUTE_CHANGES, setState);
$(document).on(ON_CANCEL_DISCARD_NEW_EVENT, setState);
$(document).on(ON_FILL_DRAWING, setState);
$(document).on(ON_FREE_FORM_BUTTON_PRESSED, setState);
$(document).on(ON_DRAW_END, setState);
$(document).on(ON_DISCARD_CHANGES, setState);
$(document).on(ON_CANCEL_DELETE_EVENT, setState);
$(document).on(ON_SAVE_ATTRIBUTES_PRESSED, setState);
$(document).on(ON_CANCEL_INSERT_NEW_EVENT, setState);
$(document).on(ON_EDIT_ATTRIBUTES_PRESSED, setState);
$(document).on(ON_DELETE_EVENT, setState);
$(document).on(ON_CLOSE_VIEW_EVENT, setState);
$(document).on(ON_EDIT_TILES_PRESSED, setState);
$(document).on(ON_EVENT_CLICKED, setState);
$(document).on(ON_CONFIRM_DELETE_EVENT, setState);
$(document).on(ON_SAVE_SUCCESS, setState);
$(document).on(ON_ERROR_SAVING_TILES, setState);

// loading indicator
$(document).on(ON_LAYER_LOADING, () => $('#progress-indicator').removeClass('hidden'));
$(document).on(ON_LAYER_LOADED, () => $('#progress-indicator').addClass('hidden'));

// click on map event
$(document).on(ON_MAP_CLICK_EVENT, renderViewEventModal);

let autoCompleteElement = $('#autoComplete');
autoCompleteElement.on('keyup', handleSearchInputKeyDown);
autoCompleteElement.on('selection', handleSelectEvent);

// pointer event handlers
$('#pointer-button').click(handlePointerButtonClicked);

// paint line event handler
$('#paint-line').click(handlePaintLine);

// select tile event handler
$('#select-tile').click(handleSelectTileOn);

$('#cancel-event-map-button').click(() => $(document).trigger(ON_DISCARD_CHANGES));

$(document).on('contextmenu', () => false);

$('#save-event-map-button').click(handleSaveButtonPressed);

$(map).on("click", handleMapClicked);

$('#confirm-delete').click(deleteEventHandler);
$('#cancel-delete').click(() => $(document).trigger(ON_CANCEL_DELETE_EVENT));

$('#discard-changes').on('cancel', handleOnDiscardCancelled)
$('#delete-confirmation').on('cancel', () => $(document).trigger(ON_CANCEL_DELETE_EVENT));