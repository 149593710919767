import {eventDisplayLayer} from "../layers/EventDisplay";
import {drawPolygonFromTiles} from "../features";
import {SELECTED_LANGUAGE_CODE} from "../constants";
import {callAPI} from "../model/Request";
import {prepareEventAttributesForm, editor} from "./EventAttributesModal";
import {ON_SAVE_SUCCESS} from "../event-constants";
import {getFeatureId} from "./ViewEvent";

export async function handleEditEventClicked (){
    // get event data
    let featureId = getFeatureId();
    let feature = eventDisplayLayer.getSource().getFeatureById(featureId);
    let eventData = feature.getGeometry().getProperties();

    await renderEditEventAttributes(eventData);

    $('#update-event-details').removeClass('hidden');
    $('#view-event-details').addClass('hidden');
}

// generate input elements for every attribute for this event type.
function renderUpdateEventAttributesInput(eventTypeAttributes){
    let attributeObject = [];
    Object.keys(eventTypeAttributes).forEach((key)=>{
        attributeObject.push({
            name: key,
            value: eventTypeAttributes[key][SELECTED_LANGUAGE_CODE],
            type: eventTypeAttributes[key].type
        })
    })

    return attributeObject;
}

function handleSaveEventAttributes(attributes){
    let newAttributes = attributes;
    Object.keys(attributes).forEach((attribute) => {
        let attributeID = attribute.replaceAll(' ', '_');
        let newValue = $(`#${attributeID}`).val();
        newAttributes[attribute][SELECTED_LANGUAGE_CODE] = newValue;
    })

    return newAttributes;
}

async function handleSaveEventData(){
    let featureId = getFeatureId();
    let feature = eventDisplayLayer.getSource().getFeatureById(featureId);

    let eventData = feature.getGeometry().getProperties();

    let description = editor.getData();

    let newAttributes = handleSaveEventAttributes(eventData.attributes);

    let event_group_id = eventData.event_group_id;

    let updatedEventData = {
        id: eventData.id,
        event_group_id: event_group_id,
        description: description,
        attributes: newAttributes,
    };

    let fields = {
        'name': 'event-name',
        'color': 'create-event-color'
    };

    Object.entries(fields).forEach(([name, field]) => {
        updatedEventData[name] = $(`#${field}`).val();
    })

    return await callAPI('edit-event', updatedEventData, [])
        .then( async () => {
            await callAPI('get-event-by-id', null, [eventData.id])
                .then((dbEvent)=>{
                    let existingFeature = eventDisplayLayer.getSource().getFeatureById(dbEvent.id)
                    drawPolygonFromTiles(dbEvent, existingFeature);
                })
        })
}

// render input fields for updating event data
function renderEventUpdateFields(event){
    let date = new Date(event.date);

    let colors = [...AVAILABLE_COLORS];

    colors.forEach((color)=> {
        if (color.isDefault) {
            color.isDefault = false;
        }

        if (color.value === event.color_hex) {
            color.isDefault = true;
        }
    })

    return {
        eventName: event.name,
        eventYear: date.getFullYear(),
        modalHeader: 'Update Event',
        modalID: 'update-event-details',
        isEdit: true,
        colors: colors,
        selectedColor: event.color_hex,
        description: event.description
    };
}

export function handleUpdateAttributes(){
    // call save handler
    // after 'saving' go back to view event data with updated data
    handleSaveEventData()
        .then(() => {
            $(document).trigger(ON_SAVE_SUCCESS);
        })
        .catch(()=>{
            handleEditEventClicked();
        })
}

async function renderEditEventAttributes(event){
    let templateObject = renderEventUpdateFields(event);
    templateObject.attributes = renderUpdateEventAttributesInput(event.attributes);

    prepareEventAttributesForm(templateObject);
}