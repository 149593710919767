import {callAPI} from "./model/Request";
import {getAppliedFilters} from "./control/AdvancedSearch";
import {ON_SEARCH_INPUT, ON_SEARCH_UNFOCUS} from "./event-constants";
import Handlebars from "handlebars";

let filters;

// function to render the search dropdown list
const handleRenderResultList = (list, data) => {
    let durationStart = filters.duration_start;
    let durationEnd = filters.duration_end;
    let template = Handlebars.compile("{{>search-list-template}}");
    let isRange = durationStart !== durationEnd;
    let isFound = data.results.length > 0;
    let templateData = {
        isRange: isRange,
        durationStart: durationStart,
        durationEnd: durationEnd,
        isFound: isFound,
        results: data.results.length,
        matches: data.matches.length,
        query: data.query
    };

    let htmlContent = template(templateData);
    $(list).prepend(htmlContent);
}

// function to render each individual item in result list
const handleRenderResultItem = (item, data) =>{
    // Modify Results Item Style
    item.style = "display: flex; justify-content: space-between;";

    // Modify Results Item Content
    let isRange = filters.duration_start !== filters.duration_end;
    let year = new Date(data.value.date).getFullYear();
    let template = Handlebars.compile("{{>search-list-item}}")
    let templateData = {
        isRange: isRange,
        match: data.match,
        year: year
    }
    let htmlContent = template(templateData);
    item.innerHTML = htmlContent;
}

let autocomplete = new autoComplete({
    // options to handle querying the data
    data: {
        // function to send the search query and return a list of matched items
        src: async () => {
            let searchKeyword = $('#autoComplete').val();
            filters = getAppliedFilters();

            let body = {
                duration_start: filters.duration_start,
                duration_end: filters.duration_end,
                eventTypes: Object.entries(filters.eventTypes).filter(([key, value]) => value === true).map(([key]) => key),
                keyword: searchKeyword
            }

            return await callAPI('search-event-autocomplete', body, null);
        },
        // key in response to search against
        keys: ["name"]
    },
    // handle rendering the result list
    resultsList: {
        element: handleRenderResultList,
        noResults: true,
        tabSelect: true,
        maxResults: 10
    },
    // handle rendering each item in the list
    resultItem: {
        element: handleRenderResultItem,
        highlight: true
    },
    events: {
        input: {
            focus: () => {
                if (autocomplete.input.value.length) autocomplete.start();
            },
            open: () => {
                $(document).trigger(ON_SEARCH_INPUT);
            },
            close: () => {
                $(document).trigger(ON_SEARCH_UNFOCUS);
            }
        }
    }
})