import Handlebars from "handlebars";
import {ON_ADD_EVENT_PRESSED, ON_EVENT_TYPES_READY} from "../event-constants";
import {callAPI} from "../model/Request";

export let eventTypes = [];
let eventTypeId;

export function initializeEventTypes(){
    // get all event types
    // open modal next to add button (or just somewhere) that has a select button of all event types
    // and a create button
    // when the create button is pressed, allow users to draw and save.
    callAPI('get-event-types')
        .then((result) => {
            eventTypes = result.types;
            $(document).trigger($.Event(ON_EVENT_TYPES_READY, {types: eventTypes}));
        })
}

// Prepare menu item HTML to render in menu popup
export function handleEventTypeMenu(e){
    let eventTypeMenu = $('#event-type-menu');
    eventTypeMenu.html("");

    let template = Handlebars.compile("{{>event-type-menu}}")
    let htmlContent = template({eventTypes: e.types});

    eventTypeMenu.append(htmlContent);

    $('#add-event').click(renderCreateEventModal);
    $('#event-type-menu').on('close-menu', handleSelectEventType);
    $('#event-type-menu').on('close-menu', handleSelectEventTypeTransition);
}

// display the event type menu
function renderCreateEventModal(){
    const menuElement = $('#event-type-menu');
    menuElement.prop('open', !menuElement.prop('open'));
}

// save the id of the event type being created
function handleSelectEventType(e){
    eventTypeId = $(e.target).attr('data-event-type-id');
}

// transition into free form draw state
function handleSelectEventTypeTransition(){
    $(document).trigger(ON_ADD_EVENT_PRESSED);
}

export function getEventTypeID(){
    return eventTypeId;
}