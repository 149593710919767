import {eventDisplayLayer} from "../layers/EventDisplay";
import Handlebars from "handlebars";
import {FILTERS_UPDATED} from "../event-constants";

// array to keep track of what events have been selected previously or not
let appliedTypeFilters = {};
let stagedTypeFilters = {};

// on page load setup event types into filter menu
export function handleEventTypeFilter(e){
    initializeFilters(e.types);

    let eventTypeFilter = $('#filter-types-dialog');
    eventTypeFilter.html("");

    let template = Handlebars.compile("{{>event-type-filter}}")
    let htmlContent = template({eventTypes: e.types});

    eventTypeFilter.append(htmlContent);

    $('#filter-event').click(handleClickFilterEventsButton);
    $('.event-type-checkbox').on('change', handleChangeFilter);
    $('#apply-filter').click(handleClickFilterButton);
    $('#apply-filter').click(triggerUpdateFilter);
    $('#apply-filter').click(handleClickFilterButtonUI);
    $('#close-filter-event-types').click(handleResetFilters);
    $('#close-filter-event-types').click(handleResetFilterUI);
}

// populate initial filters on page load
function initializeFilters(eventTypeList){
    for(let eventType of eventTypeList){
        appliedTypeFilters[`${eventType.id}`] = true;
    }

    stagedTypeFilters = {...appliedTypeFilters};
}

// When user clicks on a checkbox, handle updating that value in stagedFilters
function handleChangeFilter(e){
    let eventTypeID = $(e.target).val();
    stagedTypeFilters[eventTypeID] = $(e.target).prop('checked');
}

// trigger an update of the filter event for other components to catch
function triggerUpdateFilter(e){
    $(document).trigger($.Event(FILTERS_UPDATED, {event_types: appliedTypeFilters}))
}

// when the user clicks on this, we render the popup
function handleClickFilterEventsButton(){
    const dialogElement = $('#filter-event-types');
    dialogElement.removeClass('hidden');
}

// update filter variables
function handleClickFilterButton(){
    appliedTypeFilters = {...stagedTypeFilters};
}

// refresh the map to get new data and hide filter menu
function handleClickFilterButtonUI(){
    // refresh the map event display layer so we can get the new data
    eventDisplayLayer.getSource().clear();
    eventDisplayLayer.getSource().refresh();

    handleCloseFilterDialog();
}

// helper function to close filter dialog
function handleCloseFilterDialog(){
    const dialogElement = $('#filter-event-types');
    dialogElement.addClass('hidden');
}

// when the 'x' button is clicked, reset all the filters
function handleResetFilters(){
    stagedTypeFilters = {...appliedTypeFilters};
}

// when the 'x' button is clicked restore previous selected state and close the dialog
function handleResetFilterUI(){
    for(let eventType of Object.keys(appliedTypeFilters)){
        let element = $(`#event-type-${eventType}-filter`);
        element.prop('checked', appliedTypeFilters[eventType]);
    }

    handleCloseFilterDialog();
}

// get applied filters
export function getEventTypeFilters(){
    let filters = [];
    for(let eventTypeId of Object.keys(appliedTypeFilters)){
        if(appliedTypeFilters[`${eventTypeId}`]){
            filters.push(eventTypeId);
        }
    }
    return filters;
}